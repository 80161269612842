<script>
    import GlobalVue from '@helper/Global.vue';
    import Gen from '@helper/Gen';
    import TopBarVue from './TopBar.vue';
    export default {
        extends: GlobalVue,
        data() {
            return {
                datas:[],
                search:{},
                navName:'',
            }
        },
        async mounted() {
            await this.ready();
            // if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
            this.navName = this.$route.name
            $("#primary-menu > ul").removeClass('d-block')
            setTimeout(()=>{ SEMICOLON.widget.extras() }, 300)
            setTimeout(()=>{ SEMICOLON.header.menufunctions() }, 300)
            setTimeout(()=>{ SEMICOLON.header.init() }, 300)
        },
        methods: {
            submitSearchBottom(v){
                this.submitSearch(v);
            },
            submitSearch(v){
                $('#top-search-trigger').click()
                if(!this.search.q)return;
                this.$router.push({name:"SearchPage",params:{q:this.search.q}})
            },
        },
    };
</script>

<template>
    <header id="header" class="transparent-header static-sticky not-dark" data-sticky-class="not-dark">
        <div id="header-wrap">
            <div class="container clearfix">
                <div id="primary-menu-trigger"><i class="icon-reorder"></i></div>
                <div id="logo">
                    <h1 class="web_font">PT farmsco feed Indonesia</h1>
                    <router-link :to="{name:'index'}" class="standard-logo">
                        <VImg class="divcenter" :src="baseUrl+'/frontend/images/logo.png'" alt="Farmsco"></VImg>
                    </router-link>
                    <router-link :to="{name:'index'}" class="retina-logo">
                        <VImg class="divcenter" :src="baseUrl+'/frontend/images/logo@2x.png'" alt="Farmsco"></VImg>
                    </router-link>
                </div>

                <nav id="primary-menu" class="not-dark">
                    <ul id="over-style">
                        <li id="about"><router-link :to="{name:'AboutUs'}"><div>{{ web.mn_tentang }}</div></router-link></li>
                        <li id="product">
                            <router-link :to="{name:'ProductList'}"><div>{{ web.mn_product }}</div></router-link>
                            <ul>
                                <li v-for="(p,k) in $root.store.productMenu" :key="k">
                                    <router-link :to="{name:'Product',params:{slug:p.mp_slug}}">{{p.mp_name}}</router-link>
                                </li>
                            </ul>
                        </li>
                        <li id="customer">
                            <a href="javascript:;"><div :class="navName=='DirectMail' ? 'activeMenu' :(navName=='DirectMailDetail'?'activeMenu':(navName=='DetailCustomerActivities'?'activeMenu':''))">{{ web.mn_customer_care }}</div></a>
                            <ul>
                                <li><router-link :to="{name:'DirectMail'}"><div>{{ web.mn_dm }}</div></router-link></li>
                                <li><router-link :to="{name:'CustomerActivities'}"><div>{{ web.mn_customer_act }}</div></router-link></li>
                            </ul>
                        </li>
                        <li id="news">
                            <a href="javascript:;"><div :class="navName=='Jurnal' ? 'activeMenu' : (navName=='Artikel' ? 'activeMenu' : (navName=='JurnalDetail' ? 'activeMenu' : (navName=='ArticleDetail' ? 'activeMenu' : '') ))">{{ web.mn_ruang_berita }}</div></a>
                            <ul>
                                <li><router-link :to="{name:'Jurnal'}"><div>{{ web.mn_jurnal }}</div></router-link></li>
                                <li><router-link :to="{name:'Artikel'}"><div>{{ web.mn_artikel }}</div></router-link></li>
                                <li><router-link :to="{name:'Galeri'}"><div>{{ web.mn_gallery }}</div></router-link></li>
                            </ul>
                        </li>
                        <li><router-link :to="{name:'Contact'}"><div>{{ web. mn_contact}}</div></router-link></li>
                        <li id="links">
                            <a href="javascript:;"><div>Links</div></a>
                            <ul>
                                <li><a href="http://www.harimholdings.com/"><div>Harim Holdings</div></a></li>
                                <li><a href="http://farmsco.com/"><div>Farmsco.com</div></a></li>
                                <li><a href="https://www.clounge.com/"><div>C-Lounge</div></a> </li>
                            </ul>
                        </li>
                    </ul>
                    <div id="top-search">
                        <a href="javascript:;" id="top-search-trigger"><i class="icon-search3 col_dark_header"></i><i class="icon-line-cross col_dark_header"></i></a>
                        <VForm @resp="submitSearchBottom" method="get">
                            <input type="text" name="search" class="form-control" v-model="search.q" placeholder="Type &amp; Hit Enter..">
                        </VForm>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>